import React from "react"
import TileList from "../components/tilelist"
import SubnavList from "../components/subnavList"
import Backstory from "../components/backstory"
import Profile from "../components/profile"
import Writeups from "../components/writeups"
import { graphql } from "gatsby"

function PostSide({ data }) {
  const post = data.markdownRemark
  const slugLink = post.fields.slug
  if (slugLink.startsWith("/about/backstory")) {
    return <Backstory />
  } else if (slugLink.startsWith("/about/profile")) {
    return <Profile />
  } else if (slugLink.startsWith("/about/writeups")) {
    return <Writeups />
  } else if (slugLink.startsWith("/projects/apps")) {
    return (
      <div className="figApp">
        <iframe
          className="app"
          title={post.frontmatter.title}
          src={post.frontmatter.app}
          frameBorder="0"
        ></iframe>
      </div>
    )
  } else {
    return null
  }
}

function PostHeader({ data }) {
  const post = data.markdownRemark
  const slugLink = post.fields.slug

  if (slugLink.startsWith("/about/writeups")) {
    return (
      <div className="postHeader">
        <h1>{post.frontmatter.title}</h1>
        <time>{post.frontmatter.date}</time>
        <div className="tags">
          {post.frontmatter.tags.map((tag, i) => (
            <span key={i}>{tag}</span>
          ))}
        </div>
      </div>
    )
  } else if (
    slugLink.startsWith("/projects/lectures") ||
    slugLink.startsWith("/projects/websites") ||
    slugLink.startsWith("/about/backstory")
  ) {
    return null
  } else {
    return (
      <div className="postHeader">
        <h1>{post.frontmatter.title}</h1>
        <div className="tags">
          {post.frontmatter.tags.map((tag, i) => (
            <span key={i}>{tag}</span>
          ))}
        </div>
      </div>
    )
  }
}

function PostContent({ data }) {
  const post = data.markdownRemark

  return (
    <div className="pageContent">
      <PostHeader data={data} />
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </div>
  )
}

const postContent = ({ data }) => {
  const slugLink = data.markdownRemark.fields.slug

  if (slugLink.startsWith("/about/")) {
    return (
      <div className="about">
        <div className="contentArea post">
          <PostSide data={data} />
          <PostContent data={data} />
        </div>
        <TileList />
        <SubnavList />
      </div>
    )
  } else if (slugLink.startsWith("/projects/")) {
    return (
      <div className="projects">
        <SubnavList />
        <TileList />
        <div className="contentArea post">
          <PostSide data={data} />
          <PostContent data={data} />
        </div>
      </div>
    )
  }
}

export default postContent;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        app
      }
      id
    }
  }
`
