import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

function Writeup() {
  return (
    <div className="side">
      <h2>Select Writeups</h2>
      <p>
        Sharing a bit about myself by publishing what I write. This is where you
        can find opinionated writeups discussing various topics of interest
        including:
      </p>
      <ul className="writeupList tags">
        <li>
          <AniLink to="/tags/design">
            <span>Design</span>
          </AniLink>
        </li>
        <li>
          <AniLink to="/tags/lifestyle">
            <span>Lifestyle</span>
          </AniLink>
        </li>
        <li>
          <AniLink to="/tags/productivity">
            <span>Productivity</span>
          </AniLink>
        </li>
        <li>
          <AniLink to="/tags/technology">
            <span>Technology</span>
          </AniLink>
        </li>
        <li>
          <AniLink to="/tags/culture">
            <span>Culture</span>
          </AniLink>
        </li>
        <li>
          <AniLink to="/tags/education">
            <span>Education</span>
          </AniLink>
        </li>
        <li>
          <AniLink to="/tags">And More...</AniLink>
        </li>
      </ul>
    </div>
  )
}

export default Writeup
